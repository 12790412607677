<template>
    <div class="reset_password">
        <div class="main">
            <!-- <img class="cross btn" @click="close" src="@/assets/img/icon/cross_black.svg" alt=""> -->
            <h3>Change Password</h3>
            <div class="container">
                <div class="inputWrap">
                    <label for="password"><span class="mark">*</span>New Password</label>
                    <input type="password" id="password" name="password" placeholder="Enter your password" v-model="form.password">
                </div>   
                <div class="inputWrap">
                    <label for="confirmpass"><span class="mark">*</span>Confirm Password</label>
                    <input v-model="form.confirmpass" name="confirmpass" id="confirmpass" type="password" placeholder="Enter Confirm Password">
                </div> 
            </div>
            <div class="btnWrap">
                <button class="submit" @click="submit">Change password
                    <!-- x<span class="arrow"></span> -->
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import {resetForgotPass} from '@/api'
export default {
    name:'ResetPassword',
    data:()=>({
        form:{
            password:'',
            confirmpass:''
        },
    }),
    methods:{
        async submit(){
            let vm = this;
            if(this.form.password ==''||this.form.confirmpass==''){
                vm.$message({
                    showClose: true,
                    message:"Password can't be empty",
                    type: 'error',
                    duration: 1500,
                });
                return;
            }else{
                if(this.form.password !=this.form.confirmpass){
                    vm.$message({
                        showClose: true,
                        message:"Password not same",
                        type: 'error',
                        duration: 1500,
                    });
                    return 
                }
            }
            let param = {
                password:this.form.confirmpass,
                token:this.$route.query.token
            }
            await resetForgotPass(param).then(res=>{
                if(res.data.status==200){
                    vm.$message({
                        showClose: true,
                        message:"Reset Successfully!",
                        type: 'success',
                        duration: 1500,
                        onClose:()=>{
                            vm.$router.push('/landing');
                        }
                    });
                }else{
                    vm.$message({
                        showClose: true,
                        message:`${res.data.message}`,
                        type: 'success',
                        duration: 1500,
                    });
                }
            }).catch(function (error) {
                  if (error.response) {
                    console.log(error.response)
                    vm.$message({
                        showClose: true,
                        message: `${error.response.data.message}`,
                        type: 'error',
                        duration: 1500,
                    });
                  }
            });

            
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

.reset_password{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .main{
        width:35%;
        padding: 30px 0 40px;
        background-color:#ffffff;
        margin: auto;
        top:50%;
        transform:translateY(-50%);    
        position: relative;
        box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 12%);
        border-radius: 5px;
        h3{
            font-size: 20px;
            margin: 0 0 30px;
        }  
        p{
            font-size: 12px;
            margin: 0 0 8%;
        }
        .cross{
            display: block;
            margin: 0 0 0 auto;
            padding: 2%;
        }
        .inputWrap{
            width: 336px;
            margin:0 auto 30px;
            label{
            font-weight: 500;
            display: block;
            text-align: left;   
            font-size: 18px;
            margin-bottom: 10px;
            position: relative;
            text-indent: -7px;
            span{
                position: absolute;
                right:0;
                bottom:0;
                font-size: 12px;
                color: #FF4CAD;
                font-weight: 400;
            }
            @include for-size(desktop-only) {
                font-size:14px;
            }
            .mark{
                position:static;
                font-size: 16px;
            }
        }
            input{
                font-size: 16px;
                line-height: 2.3;
                padding-left: 15px;
                width: 100%;
                border-color: #333333;
                &:focus{
                    border-color:$purple;
                }
                &.error{
                    border:1px solid #FF4CAD;
                }
                @include for-size(desktop-only) {
                    font-size:14px;
                }
            }
        }
        .checkWrap {
            text-align: left;
            width: 336px;
            margin: 20px auto 0;
        }
        .checkWrap p{
            display: inline-block;
            padding-left: 10px;
            line-height: 19px;
            font-size: 14px;
            color:#757575;
            span{
                text-decoration: underline;
            }
        }
        .container{
            margin-bottom: 20px;
            ::v-deep .el-checkbox-group{
                padding: 0 13%;
                text-align: left;
            }
            ::v-deep .el-checkbox{
                margin-bottom: 10%;
            }
            ::v-deep .el-checkbox__inner{
                width: 17px;
                height: 17px;
            }
            ::v-deep .el-checkbox__inner::after{
                height: 9px;
                left: 6px;
                top: 1px;
            }
        }
        .btnWrap{
            width: 336px;
            margin: 0 auto;
            button{
                color:#fff;
                @include blockShadow;
                border-radius: 4px;
                font-size: 14px;
                line-height: 2.4;
                width:100%;
                
                span{
                    background-image: url('~@/assets/img/icon/arrowL.svg');
                    display: inline-block;
                    background-size: 100%;
                    width: 11.8%;
                    padding-bottom: 19%;
                    transform: rotate(180deg);
                    margin-bottom: -2%;
                    margin-left: 10px;
                }
            }
        }
        .dialog-cotent {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 95%;
            width: 300px;
            margin: 30px auto 30px;
            .type-switch{
                display: flex;
                justify-content: space-between;
                margin-bottom: 36px;
                font-size: 14px;
                div{
                    width: 145px;
                    height: 23px;
                    cursor: pointer;
                    &.active{
                        position: relative;
                        transition: .3s;
                        &:after{
                            content: "";
                            width: 100%;
                            position: absolute;
                            top: 100%;
                            left: 0;
                            border-bottom: 1px solid #4454ff;
                        }
                    }
                }
            }
            .input-box{
                display: flex;
                flex-direction: column;
                input{
                    height: 36px;
                    padding:8px 14px;
                    letter-spacing: 1.2px;
                    border: 1px solid #333;
                    &::placeholder{
                        font-size: 13px;
                        color: #b1aeae;
                    }
                    &:nth-child(2){
                        margin-top: 14px
                    }
                }
                span{
                    margin: 6px 0px;
                    align-self: flex-start;
                    color: transparent;
                    user-select: none;
                    color: transparent;
                    // font-size: 14px;
                    &.active{
                        color: #fb4a93;
                    }
                }
            }
            .forgot{
                text-align: right;
                margin: 6px 0 30px 0;
                font-size: 14px;
                color: #4454ff;
                cursor: pointer;
            }
            .not-verified{
                color: #fb4a93;
                text-align: left;
                font-size: 14px;
                margin-bottom: 6px;
                height: 0;
                overflow: hidden;
                &.active{
                    height: auto;
                }
            }
            h6{
                font-size: 14px;
                margin: 18px 0 27px 0;
            }
            .button-box{
                display: flex;
                flex-direction: column;
                button{
                    height: 35px;
                    border: none;
                    border-radius: 5px;
                    background-color: #4454ff;
                    font-size: 14px;
                    color: #fff;
                    &:nth-child(2), &.last{
                        background-color: #f7f7f7;
                        color: #333;
                        margin: 10px 0 0 0;
                    }
                    &.only-text{
                        border: none;
                        background-color: transparent;
                    }
                    &:hover{
                        filter: drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.4));
                    }
                }
            }
            .or-block{
                display: flex;
                justify-content:space-between;
                align-items:center;
                font-size: 14px;
                margin: 36px 0 14px 0;
                div{
                    width: 100px;
                    height: 1.5px;
                    background-color: #efefef;
                }
            }
            .without-account{
                margin: 22px 0 14px 0;
                font-size: 14px;
                cursor: pointer;
                color: #4454ff;
            }
            .features{
                font-size: 12px;
                color: #b1aeae;
            }
            .gray{
                font-size: 14px;
                color: #b1aeae;
            }
        }
    }
}
</style>